import React from "react"
import Layout from "../../components/layoutEn"
import SEO from "../../components/layout/seo"
import TtaCoverflowEffect from "../../components/standProj/TtaCoverflowEffect"

import standProjMain from "../../img/ttastandProjMain.png"
import standMainImg03 from "../../img/tta_content.jpg"
import tta_1 from "../../img/tta_1.png"
import tta_2 from "../../img/tta_2.png"
import tta_3 from "../../img/tta_3.png"

const TtaStand = () => (
  <Layout>
    <SEO title="M2MTech" />
    <div className="interStandWrap">
      <div className="sectionWrap StandOverview ttaOverview">
        <p className="standProjMain ttastandProjMain">
          <img src={standProjMain} alt="" />
        </p>
        <div className="textWrap">
          <h2>
            TTA <span>standard</span>
          </h2>
          <p className="bodyText">
            M2MTech established three standards for the Korea Information and
            Communication Technology Association (TTA) at the end of 2020 in
            cooperation with the Korea Elevator Safety Authority and the Korea
            Elevator Society. At the same time, in recognition of M2MTech 's
            successful standardization project and outstanding consulting
            performance in the 'ICT standard and technical advisory service for
            small and medium-sized enterprises' project implemented by TTA, it
            was awarded the "Excellent Advisory Case Award". Currently, the
            three standards are in the process of being approved as national
            standards by the National Radio Research Agency (RRA) and the
            standard committee.
          </p>
        </div>
      </div>
      <div className="sectionWrap tta">
        <div className="textWrap">
          <h2>The Achieve</h2>
          <p>
            <img src={standMainImg03} alt="" />
          </p>
          <p className="bodyText">
            This standard is a digital convergence standard between elevators
            and the IT industry, and it has high utility in safety, public
            convenience, and product commercialization in the elevator industry,
            and also contributed to the scalability of the new vertical delivery
            convergence industry ecosystem in buildings such as robot delivery.
            In particular, we applied for a foreign standard patent as a
            standard proposed/adopted/approved by the International Organization
            for Standardization, and contributed to the revitalization of the
            industry by expanding it to a standard platform in the building and
            facilitating overseas market entry. And, not only was it established
            as a TTA group standard, but it is also being considered as a
            national standard, and it is scheduled to be enacted in the first
            half of 2022.
          </p>
        </div>
      </div>

      <div className="sectionWrap elsaServices ttaStandards">
        <div className="textWrapMain">
          <h2>
            Internet of Things Gateway Standard for Elevator Remote Monitoring
          </h2>
        </div>
        <ul>
          <li className="elsaServices0 ttaServicesLi">
            <div className="ttaStandardsImg elsaServicesImg0">
              <img src={tta_1} alt="" />
            </div>
            <div className="textWrap">
              <h2>Architecture and functional requirements</h2>

              <p className="bodyText">
                This standard describes the structure of the IoT gateway for
                remote monitoring of lift and describes the interfaces and
                requirements between lift, IoT gateways and IoT servers.
              </p>
              {/* <ol>
                <li>
                  <span>연결성</span>
                  <span>– IoT Stack에 의한 데이터 소스에 쉽게 연결</span>
                  <span>– IoT Stack에 의한 데이터 소스에 쉽게 연결</span>
                </li>
                <li>
                  <span>보안성 / 안정성</span>
                  <span>– 데이터 암호화</span>
                  <span>– 보안전문 인력 보유</span>
                </li>
                <li>
                  <span>확장성</span>
                  <span>– Legacy연결을 위한 Bridge / Ext. 기능</span>
                  <span>– 오픈 플랫폼 기반</span>
                  <span>– 국제 IoT 표준규격 준수</span>
                </li>
              </ol> */}
            </div>
          </li>
          <li className="standServices1 ttaServicesLi">
            <div className="ttaStandardsImg elsaServicesImg1">
              <img src={tta_2} alt="" />
            </div>
            <div className="textWrap">
              <h2>Monitoring data model</h2>

              <p className="bodyText">
                The standard defines a monitoring data model used by IoT
                gateways, lift, and IoT application servers that support remote
                monitoring of lift.
              </p>
              {/* <ol>
                <li>
                  <span>IoT/AI 프레임워크</span>
                  <span>– 데이터 수집, 분석 및 AI학습</span>
                  <span>
                    – 프로토콜 어댑터, 데이터 버스, 최신 Web/App서비스
                    컨테이너기반 프레임워크
                  </span>
                  <span>– Node 인증, 권한관리, SW배포 기능</span>
                </li>
                <li>
                  <span>유연성</span>
                  <span>– 사용량에 따른 과금</span>
                  <span>– 단시간에 서버 확장 가능</span>
                </li>
                <li>
                  <span>편리성</span>
                  <span>– 언제, 어디서든 인터넷을 통한 접근</span>
                  <span>– Secure 브라우즈 기반 데이터 접근</span>
                  <span>– PaaS 플랫폼에 의한 Private운영센터</span>
                </li>
              </ol> */}
            </div>
          </li>
          <li className="standServices2 ttaServicesLi">
            <div className="ttaStandardsImg elsaServicesImg2">
              <img src={tta_3} alt="" />
            </div>
            <div className="textWrap">
              <h2>ELMP-485 Protocol</h2>
              <p className="bodyText">
                The standard is intended to define the communication protocol,
                ELMP-485 for remote monitoring of lift. Generally remote
                monitoring applications run on elevator control panels(ECPs),
                elevator monitoring panel(EMP), elevator monitoring gateway(EMG)
                and elevator monitoring servers(EMS). In addition, this standard
                describes the communication protocol between the EMG and the
                ECPs and EMP.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div className="sectionWrap standGallery ttaGallery">
        <div className="">
          <TtaCoverflowEffect />
        </div>
      </div>
    </div>
  </Layout>
)

export default TtaStand
