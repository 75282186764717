import React from "react"
import { Link } from "gatsby"
import { bool } from "prop-types"
import { StyledMenu } from "./Menu.styled"

const Menu = ({ open, ...props }) => {
  const isHidden = open ? true : false
  const tabIndex = isHidden ? 0 : -1

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <Link to="/ElsaEn/" tabIndex={tabIndex}>
        ELSA
      </Link>
      <Link to="/RobotEn/">Smart Mobility</Link>
      <Link to="#none" className="MenuMobile fobMenuMobile">
        field of business
        <ul>
          <li>
            <Link to="/business/ElevatorEn/">Elevator</Link>
          </li>
          <li>
            <Link to="/business/EscalatorEn/">Escalator</Link>
          </li>
          <li>
            <Link to="/business/ParkingTowerEn/">Parking&nbsp;Tower</Link>
          </li>
          <li>
            <Link to="/business/SiEn/">SI</Link>
          </li>
          <li>
            <Link to="/business/HvacEn/">HVAC</Link>
          </li>
          <li>
            <Link to="/business/BemsEn/">BEMS</Link>
          </li>
          <li>
            <Link to="/business/LogisticsEn/">Logistics</Link>
          </li>
          <li>
            <Link to="/business/AiEn/">AI service</Link>
          </li>
        </ul>
      </Link>
      <Link to="#none" className="MenuMobile stanProjMenuMobile">
        Standardization
        <ul>
          <li>
            <Link to="/standard/InterStandEn/">
              International ITU-T Y.Series
            </Link>
          </li>
          <li>
            <Link to="/standard/InterStandY4420En/">
              International ITU-T Y4420
            </Link>
          </li>
          {/* <li>
            <Link to="/standard/NationStandEn/">National&nbsp;standard</Link>
          </li> */}
          {/* <li>
            <Link to="/standard/TtaStandEn/">TTA&nbsp;standard</Link>
          </li> */}
          <li>
            <Link to="/standard/GroupStandEn/">Organization&nbsp;standard</Link>
          </li>
        </ul>
      </Link>
      <Link to="/AboutUsEn/" tabIndex={tabIndex}>
        about us
      </Link>
      <Link to="/PressEn/" tabIndex={tabIndex}>
        news &amp; press
      </Link>
      <Link to="/ContactEn/" tabIndex={tabIndex}>
        contact us
      </Link>
      <Link to="/" tabIndex={tabIndex}>
        Kor
      </Link>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu
//<a href="/" tabIndex={tabIndex}>
//<span aria-hidden="true">📩</span>
//Contact
//</a>
