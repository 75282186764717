import React from "react"
import logo from "../../img/logo/logo.svg"
import mapIcon from "../../img/mapIcon.svg"
import Link from "gatsby-plugin-transition-link"
import {
  companyMail,
  companyHp,
  companyNameEn,
  companyTelEn,
  companyFaxEn,
  companyAddEn,
} from "../configs/ShortCode"
import logoTwit from "../../img/logo/logo_twit.png"
import logoYout from "../../img/logo/logo_yout.png"

const textWstrong = { fontWeight: "bold" }
function Footer() {
  return (
    <div className="sectionWrap footerWrap">
      <p className="companyBottomLogo imgWrap">
        <img src={logo} alt="logo" />
      </p>
      <ul className="companyInfo">
        <li style={textWstrong}>{companyNameEn}</li>
        <li>
          {companyAddEn}
          <Link to="/ContactEn" className="mapView">
            <p className="mapIcon">
              <img src={mapIcon} alt="" />
            </p>
            Map view
          </Link>
        </li>
        <li>
          <span style={textWstrong}>T</span> {companyTelEn}
        </li>
        <li>
          <span style={textWstrong}>F</span> {companyFaxEn}
        </li>
        <li>
          <span style={textWstrong}>M</span> {companyMail}
        </li>
        <li>
          <span style={textWstrong}>H</span> {companyHp}
        </li>
      </ul>
      <div className="snsLinks">
        <ul>
          <li className="twitter">
            <a href="https://twitter.com/SecuIoT_M2MTECH" title="twitter">
              <img src={logoTwit} alt="" />
            </a>
          </li>
          <li className="youtube">
            <a
              href="https://www.youtube.com/channel/UCJS2s-_iyJ34l7bBPdmkEGQ"
              title=""
            >
              <img src={logoYout} alt="youtube" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Footer
