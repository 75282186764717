import { Link } from "gatsby"
import React, { useMemo } from "react"
import logoW from "../../img/logo/logo_w.svg"
import { useLocation } from "@reach/router"
import NavMobile from "./NavMobileEn"

function Header() {
  const location = useLocation()
  const path = useMemo(() => {
    const parts = location.pathname.split("/")
    if (parts.length > 0) {
      return parts[1]
    }
    return ""
  }, [location.pathname])

  return (
    <header className={`headerWrap ${path}`}>
      <p className="companyTopLogo imgWrap">
        <Link to="/en/">
          <img src={logoW} alt="" />
        </Link>
      </p>
      <nav className="gnbUl">
        <Link to="/ElsaEn/">ELSA</Link>
        <Link to="/RobotEn/">Smart Mobility</Link>
        <Link to="#none" className="dropDownMenu fobMenu">
          Field of Business
          <ul>
            <li>
              <Link to="/business/ElevatorEn/">Elevator</Link>
            </li>
            <li>
              <Link to="/business/EscalatorEn/">Escalator</Link>
            </li>
            <li>
              <Link to="/business/ParkingTowerEn/">Parking&nbsp;Tower</Link>
            </li>
            <li>
              <Link to="/business/SiEn/">SI</Link>
            </li>
            <li>
              <Link to="/business/HvacEn/">HVAC</Link>
            </li>
            <li>
              <Link to="/business/BemsEn/">BEMS</Link>
            </li>
            <li>
              <Link to="/business/LogisticsEn/">Logistics</Link>
            </li>
            <li>
              <Link to="/business/AiEn/">AI service</Link>
            </li>
          </ul>
        </Link>
        <Link to="#none" className="dropDownMenu stanProjMenu">
          standardization
          <ul>
            <li>
              <Link to="/standard/InterStandEn/">
                International ITU-T Y.Series
              </Link>
            </li>
            <li>
              <Link to="/standard/InterStandY4420En/">
                International ITU-T Y4420
              </Link>
            </li>
            {/* <li>
              <Link to="/standard/NationStandEn/">National&nbsp;standard</Link>
            </li> */}
            {/* <li>
              <Link to="/standard/TtaStandEn/">TTA&nbsp;standard</Link>
            </li> */}
            <li>
              <Link to="/standard/GroupStandEn/">
                Organization&nbsp;standard
              </Link>
            </li>
          </ul>
        </Link>
        <Link to="/AboutUsEn/">About Us</Link>
        <Link to="/PressEn/">News &amp; Press</Link>
        <Link to="/ContactEn/">Contact Us</Link>
        <Link to="/">Kor</Link>
      </nav>
      <nav className="gnbUlMobile">
        <NavMobile />
      </nav>
    </header>
  )
}

export default Header
