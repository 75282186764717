import React from "react"
import Swiper from "react-id-swiper"
import screen00 from "../../img/tta_page_1.jpg"
import screen01 from "../../img/tta_page_2.jpg"
import screen02 from "../../img/tta_page_3.jpg"
import screen03 from "../../img/tta_page_4.jpg"
import screen04 from "../../img/tta_page_5.jpg"
import "../../styles/swiper.css"

const MutipleSlidesPerView = () => {
  const params = {
    slidesPerView: 2,
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  }
  return (
    <Swiper {...params}>
      <div className="keicDoc">
        <img src={screen00} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen01} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen02} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen03} alt="" />
      </div>
      <div className="keicDoc">
        <img src={screen04} alt="" />
      </div>
    </Swiper>
  )
}
export default MutipleSlidesPerView
